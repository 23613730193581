/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty, isUndefined, mapValues, omitBy, snakeCase } from 'lodash-es';
import {
	init, instrumentAngularRouting, setUser, addBreadcrumb, captureException, Breadcrumb, setTags, Integrations, BrowserProfilingIntegration, BrowserTracing as BrowserTracingIntegration
} from '@sentry/angular-ivy';
import { ErrorEvent, Integration, User } from '@sentry/types';
import {
	CaptureConsole as CaptureConsoleIntegration,
	ReportingObserver as ReportingObserverIntegration,
	ExtraErrorData as ExtraErrorDataIntegration
} from '@sentry/integrations';

import { IBaseEnvironmentConfig } from '@bp/shared/typings';
import { toPlainObject } from '@bp/shared/utilities/core';
import { isIgnoredPullRequestPreviewError } from '@bp/shared/features/sentry';

import { BpError } from '@bp/frontend/models/core';

import { createSentryMetaReducer } from './ngrx/create-sentry.meta-reducer';
import { IReporter, IReporterUser, ReporterError } from './reporter.interface';
import { ReportEventSource } from './report-event-source.enum';
import { SOURCE_TAG } from './constants';

const TELEMETRY_CATEGORY = 'telemetry';

export class SentryReporter implements IReporter {

	readonly logMetaReducer = createSentryMetaReducer();

	private get __isProduction(): boolean {
		return this._options.environment === 'production';
	}

	private get __isPullRequestPreview(): boolean {
		return this._options.environment === 'pull-request-preview';
	}

	private get __isStaging(): boolean {
		return this._options.environment === 'staging';
	}

	private get __isLocal(): boolean {
		return this._options.environment === 'local';
	}

	private _user: User = {};

	constructor(
		private readonly _options: {
			appId: string;
			environment: IBaseEnvironmentConfig['name'];
			release: string;
			useReplay?: boolean;
			captureConsoleErrors: boolean;
		},
	) {
		void this._init();
	}

	private async _init(): Promise<void> {
		void init({
			dsn: this._options.appId,
			tunnel: this.__isLocal ? undefined : '/sentry/tunnel',
			environment: snakeCase(this._options.environment),
			release: this._options.release,

			/*
			 * This enables included instrumentation (highly recommended), but is not
			 * necessary for purely manual usage
			 */
			integrations: [
				new Integrations.Breadcrumbs({
					console: true,
					history: true,
					xhr: true,
					fetch: true,
					sentry: true,
				}),

				new BrowserProfilingIntegration(),

				new BrowserTracingIntegration({
					routingInstrumentation: instrumentAngularRouting,
					shouldCreateSpanForRequest: url => url.includes(`${ location.host }/api`),
				}),

				new ReportingObserverIntegration(
					{
						types: [ 'crash', 'intervention' ],
					},
				),

				new ExtraErrorDataIntegration({
					depth: 4,
				}),

				...await this.__tryInitReplay(),

				...this._options.captureConsoleErrors
					? [ new CaptureConsoleIntegration({ levels: [ 'error' ]}) ]
					: [],
			],

			replaysOnErrorSampleRate: this.__isProduction ? 1 : 0.5,
			replaysSessionSampleRate: 0,
			tracesSampleRate: 0,
			profilesSampleRate: 0,
			attachStacktrace: true,
			normalizeDepth: 0,
			autoSessionTracking: true,
			denyUrls: [
				/quantum-zalora-test/u,
			],
			ignoreErrors: [
				// firebase noise
				'INTERNAL UNHANDLED ERROR',
				'INTERNAL ASSERTION FAILED: Unexpected state',
				'Detected an update time that is in the future',
				'Failed to obtain primary lease for action',
				'Failed to get document because the client is offline',
				'FirebaseError: Installations: Could not process request. Application offline',
				'Could not reach Cloud Firestore backend.',
				'Installations: Generate Auth Token request failed with err',
				'Installations: Firebase Installation is not registered.',
				'Unhandled Promise rejection: Unexpected end of JSON input',
				// rest noise
				'ChunkLoadError',
				'FetchEvent.respondWith received an error: Load failed',
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications',
				'Object Not Found Matching Id', // https://github.com/getsentry/sentry-javascript/issues/3440
				'cloudflareaccess.com/cdn-cgi',
				'Moment Timezone found Etc/Unknown from the Intl api, but did not have that data loaded.',
				'TypeError: Load failed',
				'Service worker registration failed with',
				'Unable to send Replay - max retries exceeded',
				'Unable to send Replay',
				'Failed to construct \'Worker\': Script at \'https://cdn.lr-in-prod.com',
				'LogRocket has already been loaded, loading a second instance is not supported.',
				'Trx can\'t be null, check logs for details, possibly due to mid filtering',
				'No credentials found for PSP',
			],
			beforeBreadcrumb: (breadcrumb, _hint) => {
				breadcrumb = this.__sanitizeFirestoreWarning(breadcrumb);

				return breadcrumb;
			},
			beforeSend: (event, _hint) => {
				if (this.__isIgnoredError(event))
					return null;

				event = this.__groupCertainEventsByMessage(event);

				return event;
			},
		});
	}

	private async __tryInitReplay(): Promise<Integration[]> {
		if (!this._options.useReplay)
			return [];

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { Replay } = await import('@sentry/replay');

		return [
			new Replay({
				mask: [ '.private', '.sentry-mask', '[data-sentry-mask]' ],
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
			}),
		];
	}

	identifyUser(user: IReporterUser): void {
		this._user = {
			...this._user,
			...omitBy({
				...user,
				id: user.id ?? user.email,
				email: user.email,
			}, isUndefined),
		};

		void setUser(this._user);
	}

	captureError(error: ReporterError, source: ReportEventSource): void {
		if (error instanceof BpError)
			error.originalError = undefined;

		const isHttpError = error instanceof BpError && error.isHttpError;
		const requestUrl = isHttpError ? error.requestUrl : undefined;

		captureException(
			error,
			{
				level: isHttpError ? 'warning' : 'error',
				tags: {
					[SOURCE_TAG]: source,
					...requestUrl ? { requestUrl } : {},
				},
			},
		);
	}

	captureMessage(message: string): void {
		const logError = new Error(message);

		logError.name = 'Log';

		captureException(logError, {
			level: 'info',
			tags: { [SOURCE_TAG]: ReportEventSource.Manual },
		});
	}

	warn(message: string, ...payload: any[]): void {
		void addBreadcrumb({
			message,
			category: TELEMETRY_CATEGORY,
			level: 'warning',
			data: this.__convertOptionalParamsToContext(payload),
		});
	}

	log(message: string, ...payload: any[]): void {
		void addBreadcrumb({
			message,
			category: TELEMETRY_CATEGORY,
			level: 'log',
			data: this.__convertOptionalParamsToContext(payload),
		});
	}

	track(_key: string, _value: any): void {
		// sentry doesn't need to track user actions
	}

	setTags(tags: Parameters<IReporter['setTags']>[0]): void {
		setTags(tags);
	}

	private __isIgnoredError(event: ErrorEvent): boolean {
		if ((this.__isPullRequestPreview || this.__isStaging) && isIgnoredPullRequestPreviewError(event))
			return true;

		const stacktraceFrames = event.exception?.values?.[0].stacktrace?.frames;

		if (!stacktraceFrames)
			return false;

		const ignoreRegExps = [
			// frame produced by NordVPN extensions like `(/fCyfE9jGodyx62ZS:1:12584)`
			// https://bridgerpay.sentry.io/share/issue/4a52a886831c4e9da4e7174536802572/
			/\/(?=.*[A-Za-z])[A-Za-z0-9_-]{16}$/u, //
		];

		for (const frame of stacktraceFrames) {
			if (frame.filename && ignoreRegExps.some(regExp => regExp.test(frame.filename!)))
				return true;
		}

		return false;
	}

	private __groupCertainEventsByMessage(event: ErrorEvent): ErrorEvent {
		const message = event.exception?.values?.[0].value ?? event.message;

		if (!message)
			return event;

		if ([ ReportEventSource.Manual, ReportEventSource.Api ].includes(<ReportEventSource>event.tags?.[SOURCE_TAG]))
			event.fingerprint = [ message ];

		return event;
	}

	private __convertOptionalParamsToContext(payload: any[]): Record<string, Record<string, unknown>> | undefined {
		if (isEmpty(payload))
			return;

		return payload.length === 1
			? { payload: payload[0] }
			: mapValues(payload);
	}

	private __sanitizeFirestoreWarning(breadcrumb: Breadcrumb): Breadcrumb {
		if (breadcrumb.message?.includes('Connection WebChannel transport errored') && breadcrumb.data?.['arguments'])
			breadcrumb.data['arguments'] = toPlainObject(breadcrumb.data['arguments']);

		return breadcrumb;
	}
}
