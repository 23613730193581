import { ErrorEvent } from '@sentry/types';

export function isIgnoredPullRequestPreviewError(event: ErrorEvent): boolean {
	const errorValue = event.exception?.values?.[0].value;

	return !!errorValue
		&& [
			'Unknown feature permission',
			'Unknown feature action',
		].some(message => errorValue.includes(message));
}
